
let categories = [
	{
		img: require('../../../images/categories/c1.svg'),
		name: 'UPSC'
	},
	{
		img: require('../../../images/categories/c2.svg'),
		name: 'UPSC'
	},
	{
		img: require('../../../images/categories/c3.svg'),
		name: 'UPSC'
	},
	{
		img: require('../../../images/categories/c4.svg'),
		name: 'UPSC'
	},
	{
		img: require('../../../images/categories/c5.svg'),
		name: 'UPSC'
	}
]

export {categories}