
let stats = [
	{
		name: 'courses',
		number: '60'
	},
	{
		name: 'students',
		number: '1500'
	},
	{
		name: 'followers',
		number: '20,000'
	},
]

export {stats}