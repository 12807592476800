
let teachers = [
	{
		name: 'Sujit Srivastava',
		material: 'Maths',
		img: require('../../../images/teachers/1.jpg'),
	},
	{
		name: 'Sujit Srivastava',
		material: 'Maths',
		img: require('../../../images/teachers/2.jpg')
	},
	{
		name: 'Sujit Srivastava',
		material: 'Maths',
		img: require('../../../images/teachers/3.jpg')
	},
	{
		name: 'Sujit Srivastava',
		material: 'Maths',
		img: require('../../../images/teachers/4.jpg')
	},
	{
		name: 'Sujit Srivastava',
		material: 'Maths',
		img: require('../../../images/teachers/5.jpg')
	},
	{
		name: 'Sujit Srivastava',
		material: 'Maths',
		img: require('../../../images/teachers/6.jpg')
	}
]

export {
	teachers
}