

let section1 = [
	require('../../../../images/courses/1.svg'),
	require('../../../../images/courses/2.svg'),
	require('../../../../images/courses/3.svg'),
	require('../../../../images/courses/4.svg'),
	require('../../../../images/courses/5.svg'),
	require('../../../../images/courses/6.svg'),
	require('../../../../images/courses/7.svg'),
	require('../../../../images/courses/8.svg'),
	require('../../../../images/courses/9.svg'),
	require('../../../../images/courses/10.svg'),
	require('../../../../images/courses/11.svg'),
	require('../../../../images/courses/12.svg')

]

export {
	section1
}