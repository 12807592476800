
let section2 = [
	require('../../../../images/courses/1.jpeg') ,
	require('../../../../images/courses/2.jpeg') ,
	require('../../../../images/courses/3.jpeg') ,
	require('../../../../images/courses/4.jpeg') ,
	require('../../../../images/courses/5.jpeg') ,
	require('../../../../images/courses/6.jpeg') ,
	require('../../../../images/courses/7.jpeg') ,
	require('../../../../images/courses/8.jpeg') ,
	require('../../../../images/courses/9.jpeg') ,
	require('../../../../images/courses/10.jpeg') ,
	require('../../../../images/courses/11.jpeg') ,
	require('../../../../images/courses/12.jpeg')
]

export {section2}