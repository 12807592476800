

let section1 = [
	require('../../../images/blogs/1.jpg'),
	require('../../../images/blogs/2.jpg'),
	require('../../../images/blogs/3.jpg'),
	require('../../../images/blogs/4.jpg')
]

let section2 = [
	require('../../../images/blogs/5.jpg'),
	require('../../../images/blogs/6.jpg'),
	require('../../../images/blogs/7.jpg'),
	require('../../../images/blogs/8.jpg')
]

let section3 = [
	require('../../../images/blogs/9.jpg'),
	require('../../../images/blogs/10.jpg'),
	require('../../../images/blogs/11.jpg'),
	require('../../../images/blogs/12.jpg')
]


export {section1, section2, section3}