
let tes = [
	{
		img: require('../../../images/tes/1.jpg'),
		name: 'Sujit Srivastava',
		skill: 'Maths Teacher'
	},
	{
		img: require('../../../images/tes/2.jpg'),
		name: 'Sujit Srivastava',
		skill: 'Maths Teacher'
	},
	{
		img: require('../../../images/tes/3.jpg'),
		name: 'Sujit Srivastava',
		skill: 'Maths Teacher'
	}
]

export {tes}